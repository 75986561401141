import React, { useState } from "react"

interface TooltipProps {
  text: string | string[]
  position?: "top" | "bottom" | "left" | "right" // Position du tooltip
  children: React.ReactNode // Composant enfant qui déclenche le tooltip
}

const Tooltip: React.FC<TooltipProps> = ({ text, position = "top", children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const tooltipPosition = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-3",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-3",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-3",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-3"
  }

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}>
      {isVisible && (
        <div
          className={`tooltip absolute ${tooltipPosition[position]} bg-gray-800 text-white text-sm rounded py-2 px-3 z-50 shadow-lg min-w-[150px] max-w-xs flex items-center justify-center`}>
          <div className={`tooltip-arrow ${position}`} />
          <div className="text-center">
            {Array.isArray(text) ? text.map((line, index) => <div key={index}>{line}</div>) : text}
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default Tooltip
